import { template as template_3a62c3966c0d40ac89a07e3dd2219214 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3a62c3966c0d40ac89a07e3dd2219214(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
