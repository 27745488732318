import { template as template_c2e84058a0444a1e82d0f159d3ed6558 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c2e84058a0444a1e82d0f159d3ed6558(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
