import { template as template_87a53ae526ad41bf9f6121d24c6bd3ef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_87a53ae526ad41bf9f6121d24c6bd3ef(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
